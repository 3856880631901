import localFont from "next/font/local";

const sfpro = localFont({
  src: [
    {
        path: "../../public/assets/clapps/fonts/SFProText-Heavy.ttf",
        weight: "900",
        style: "normal"
    },
    {
        path: "../../public/assets/clapps/fonts/SFProText-Bold.ttf",
        weight: "700",
        style: "normal",
    },
    // {
    //     path: "../../public/assets/clapps/fonts/SFProText-Semibold.ttf",
    //     weight: "600",
    //     style: "normal",
    // },
    // {
    //     path: "../../public/assets/clapps/fonts/SFProText-Medium.ttf",
    //     weight: "500",
    //     style: "normal",
    // },
    {
        path: "../../public/assets/clapps/fonts/SFProText-Regular.ttf",
        weight: "400",
        style: "normal",
    },
    // {
    //     path: "../../public/assets/clapps/fonts/SFProText-Light.ttf",
    //     weight: "300",
    //     style: "normal",
    // },
    // {
    //     path: "../../public/assets/clapps/fonts/SFProText-HeavyItalic.ttf",
    //     weight: "900",
    //     style: "italic",
    // },
    // {
    //     path: "../../public/assets/clapps/fonts/SFProText-BoldItalic.ttf",
    //     weight: "700",
    //     style: "italic",
    // },
    // {
    //     path: "../../public/assets/clapps/fonts/SFProText-SemiboldItalic.ttf",
    //     weight: "600",
    //     style: "italic",
    // },
    // {
    //     path: "../../public/assets/clapps/fonts/SFProText-MediumItalic.ttf",
    //     weight: "500",
    //     style: "italic",
    // },
    // {
    //     path: "../../public/assets/clapps/fonts/SFProText-RegularItalic.ttf",
    //     weight: "400",
    //     style: "italic",
    // },
    // {
    //     path: "../../public/assets/clapps/fonts/SFProText-LightItalic.ttf",
    //     weight: "300",
    //     style: "italic",
    // },
  ],
  variable: "--font-sfpro",
});

export default sfpro;