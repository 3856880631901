import sfpro from '@/theme/localFont';
import '@/styles/globals.css'
//import '@/styles/deviceMorphing.scss'
import '../../public/assets/fonts/uicons/css/bold/rounded.css'
import '../../public/assets/fonts/uicons/css/regular/rounded.css'
import '../../public/assets/fonts/uicons/css/solid/rounded.css'
import '../../public/assets/fonts/uicons/css/brands/all.css'
import * as React from 'react';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import PropTypes from 'prop-types';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '../theme/theme';
//import { SWRConfig } from "swr";
//import fetchJson from "../../lib/fetchJson";
//import Metrika from '@/components/Metrika'
import { YandexMetricaProvider } from 'next-yandex-metrica';
import { GoogleAnalytics } from '@next/third-parties/google'

export default function MyApp(props) {

  const { Component, pageProps } = props;
  
  return (
    <>
        {/* <Metrika /> */}
        <AppCacheProvider {...props}>
          <ThemeProvider theme={theme} >
            <GoogleAnalytics gaId="G-1PH8G8G02G" />
            <YandexMetricaProvider
              tagID={94989217}
              initParameters={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
            >
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <div className={`flex flex-col items-center ${sfpro.className}`}>
                <Component {...pageProps} />
              </div>
            </YandexMetricaProvider>
          </ThemeProvider>
        </AppCacheProvider>
      </>
  )
}




MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  //pageProps: PropTypes.object.isRequired,
};


